import theme from "./../../styles/theme.scss";

const
	merge = require("deepmerge"),
	HeadingReset = {
		display: "block",
		margin: "0 0 2rem",
		padding: "0",
		"* + &": {
			marginTop: "4rem"
		},
		"&:last-child": {
			marginBottom: "0"
		}
	},
	InteriorPageHeading = () => {
		return {
			base: {
				...HeadingReset,
				fontFamily: theme.heading_InteriorPageHeading_font_family,
				lineHeight: theme.heading_InteriorPageHeading_line_height
			},
			caption: {
				display: "none"
			},
			content: {
				fontFamily: theme.heading_InteriorPageHeading_font_family,
				fontSize: theme.heading_InteriorPageHeading_font_size,
				fontWeight: theme.heading_InteriorPageHeading_font_weight,
				letterSpacing: theme.heading_InteriorPageHeading_letter_spacing,
				lineHeight: theme.heading_InteriorPageHeading_line_height,
				textTransform: theme.heading_InteriorPageHeading_text_transform
			}
		};
	},
	InteriorPageHeadingCentered = () => {
		const
			Interior = merge({}, InteriorPageHeading());

		return {
			base: merge(
				Interior.base,
				{
					color: "#FFFFFF",
					textAlign: theme.heading_InteriorSubPageHeading_text_align
				}
			),
			caption: merge(
				Interior.caption,
				{
					textAlign: theme.heading_InteriorSubPageHeading_caption_text_align
				}
			),
			content: merge(
				Interior.content,
				{
					textAlign: theme.heading_InteriorSubPageHeading_text_align
				}
			)
		};
	},
	InteriorPageSubHeading = () => {
		return {
			base: {
				...HeadingReset,
				lineHeight: theme.heading_InteriorSubPageHeading_line_height,
				textAlign: theme.heading_InteriorSubPageHeading_text_align
			},
			caption: {
				color: theme.heading_InteriorSubPageHeading_caption_color,
				display: theme.heading_InteriorSubPageHeading_caption_display,
				fontFamily: theme.heading_InteriorSubPageHeading_caption_font_family,
				fontSize: theme.heading_InteriorSubPageHeading_caption_font_size,
				fontWeight: theme.heading_InteriorSubPageHeading_caption_font_weight,
				letterSpacing: theme.heading_InteriorSubPageHeading_caption_letter_spacing,
				lineHeight: theme.heading_InteriorSubPageHeading_caption_line_height,
				textAlign: theme.heading_InteriorSubPageHeading_caption_text_align,
				textTransform: theme.heading_InteriorSubPageHeading_caption_text_transform
			},
			content: {
				color: theme.heading_InteriorSubPageHeading_color,
				fontFamily: theme.heading_InteriorSubPageHeading_font_family,
				fontSize: theme.heading_InteriorSubPageHeading_font_size,
				lineHeight: theme.heading_InteriorSubPageHeading_line_height,
				textAlign: theme.heading_InteriorSubPageHeading_text_align
			}
		};
	},
	CardHeading = () => {
		return {
			base: {
				...HeadingReset
			},
			caption: {
				display: "none"
			},
			content: {
				color: theme.heading_CardHeading_color,
				fontFamily: theme.heading_CardHeading_font_family,
				fontSize: theme.heading_CardHeading_font_size,
				fontWeight: theme.heading_CardHeading_font_weight,
				lineHeight: theme.heading_CardHeading_line_height
			}
		};
	},
	RTEHeading = () => {
		return {
			base: {
				...HeadingReset,
				color: theme.heading_RTEHeading_color
			},
			caption: {
				display: "none"
			},
			content: {
				color: theme.heading_RTEHeading_color,
				fontFamily: theme.heading_RTEHeading_font_family,
				fontSize: theme.heading_RTEHeading_font_size,
				fontWeight: theme.heading_RTEHeading_font_weight,
				lineHeight: theme.heading_RTEHeading_line_height
			}
		};
	},
	RTESubHeading = () => {
		return {
			base: {
				...HeadingReset
			},
			caption: {
				display: "none"
			},
			content: {
				fontFamily: theme.heading_RTESubHeading_font_family,
				fontSize: theme.heading_RTESubHeading_font_size,
				fontWeight: theme.heading_RTESubHeading_font_weight
			}
		};
	};

export { InteriorPageHeading, InteriorPageHeadingCentered, InteriorPageSubHeading, CardHeading, RTEHeading, RTESubHeading };