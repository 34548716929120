import React, { lazy } from "react";

const
	viewComponents = {
		PageNotFound: lazy(() => import("../views/404/index")),
		HomePage: lazy(() => import("../views/App/index")),
		RootView: lazy(() => import("./../views/RootView")),
		ParentsLanding: lazy(() => import("../views/parents/index")),
		WhySave: lazy(() => import("../views/parents/why-save")),
		ActSatScores: lazy(() => import("../views/parents/act-sat-scores")),
		SkyrocketingCollegeCosts: lazy(() => import("../views/parents/skyrocketing-college-costs")),
		ScholarshipsSearchTips: lazy(() => import("../views/parents/scholarship-search-tips")),
		YourChildAndMoney: lazy(() => import("../views/parents/your-child-and-money")),
		YouAndTheFafsa: lazy(() => import("../views/parents/you-and-the-fafsa")),
		CounselorsLanding: lazy(() => import("../views/counselors/index")),
		AscaCareerStandards: lazy(() => import("../views/counselors/asca-career-standards")),
		ClassroomActivity: lazy(() => import("../views/counselors/classroom-activity")),
		CounselorsForms: lazy(() => import("../views/counselors/forms")),
		JobShadowing: lazy(() => import("../views/counselors/set-up-job-shadowing-program")),
		FinancialAidEvent: lazy(() => import("../views/counselors/financial-aid-event")),
		AboutIndex: lazy(() => import("../views/about/index")),
		ContactUs: lazy(() => import("../views/about/contact-us")),
		SiteMap: lazy(() => import("../views/about/site-map")),
		WebBanners: lazy(() => import("../views/about/web-banners")),
		StudentsLanding: lazy(() => import("../views/students/index")),
		StudentsMySmartBorrowing: lazy(() => import("../views/students/my-smart-borrowing")),
		StudentsCareerLanding: lazy(() => import("../views/students/career-planning/index")),
		StudentsCareerPlanningChecklists: lazy(() => import("../views/students/career-planning/checklists")),
		StudentsCareerExploreLanding: lazy(() => import("../views/students/career-planning/explore-salary-pay/index")),
		StudentsCareerExplorePayOff: lazy(() => import("../views/students/career-planning/explore-salary-pay/tuition-costs-earning-power")),
		StudentsCareerExploreMore: lazy(() => import("../views/students/career-planning/explore-salary-pay/learn-more-earn-more")),
		StudentsCareerExploreExpect: lazy(() => import("../views/students/career-planning/explore-salary-pay/what-to-expect-after-graduating")),
		StudentsCareerFindLanding: lazy(() => import("../views/students/career-planning/find-careers/index")),
		StudentsCareerFindClusters: lazy(() => import("../views/students/career-planning/find-careers/career-clusters")),
		StudentsCareerFindCareers: lazy(() => import("../views/students/career-planning/find-careers/careers")),
		StudentsCareerFindHotJobs: lazy(() => import("../views/students/career-planning/find-careers/hot-jobs")),
		StudentsCareerFindMoney: lazy(() => import("../views/students/career-planning/find-careers/not-about-money")),
		StudentCareerPlanningGetExperience: lazy(() => import("../views/students/career-planning/get-experience")),
		StudentsPayingLanding: lazy(() => import("../views/students/paying-for-school/index")),
		StudentsPayingCalculatorLanding: lazy(() => import("../views/students/paying-for-school/calculators/index")),
		StudentsCalculatorsEstimateMonthlyPayments: lazy(() => import("../views/students/paying-for-school/calculators/estimate-monthly-payments")),
		StudentsCalculatorsReachSavingsGoals: lazy(() => import("../views/students/paying-for-school/calculators/reach-savings-goals")),
		StudentsCalculatorsWatchSavingsGrow: lazy(() => import("../views/students/paying-for-school/calculators/watch-savings-grow")),
		StudentsPayingWaysToPayLanding: lazy(() => import("../views/students/paying-for-school/ways-to-pay/index")),
		StudentsPayingReduceCollegeCosts: lazy(() => import("../views/students/paying-for-school/ways-to-pay/reduce-college-costs")),
		StudentsPayingScholarshipScams: lazy(() => import("../views/students/paying-for-school/ways-to-pay/scholarship-scams")),
		StudentsPayingGrants: lazy(() => import("../views/students/paying-for-school/ways-to-pay/grants")),
		StudentsPayingScholarships: lazy(() => import("../views/students/paying-for-school/ways-to-pay/scholarships")),
		StudentsPayingLoans: lazy(() => import("../views/students/paying-for-school/ways-to-pay/loans")),
		StudentsPayingWorkStudyEmployment: lazy(() => import("../views/students/paying-for-school/ways-to-pay/work-study-employment")),
		StudentsPayingMilitaryService: lazy(() => import("../views/students/paying-for-school/ways-to-pay/military-service")),
		StudentsPayingManagingMoney: lazy(() => import("../views/students/paying-for-school/managing-money")),
		StudentsPayingQuestionsAndAnswers: lazy(() => import("../views/students/paying-for-school/questions-and-answers")),
		StudentsPayingSavingAndReducingCosts: lazy(() => import("../views/students/paying-for-school/saving-and-reducing-costs")),
		StudentsPayingFinancialAid101: lazy(() => import("../views/students/paying-for-school/financial-aid-101")),
		StudentsPayingFinancialAidChecklists: lazy(() => import("../views/students/paying-for-school/financial-aid-checklists")),
		StudentsPayingFinancialAidOffers: lazy(() => import("../views/students/paying-for-school/financial-aid-offers")),
		StudentsPayingAvoidingStickerShock: lazy(() => import("../views/students/paying-for-school/avoiding-sticker-shock")),
		StudentsPreparingForSchoolLanding: lazy(() => import("../views/students/preparing-for-school/index")),
		StudentsPreparingCollegeLanding: lazy(() => import("../views/students/preparing-for-school/college-prep/index")),
		StudentsPreparingCollegeAthletes: lazy(() => import("../views/students/preparing-for-school/college-prep/athletes")),
		StudentsPreparingCollegeDisabilities: lazy(() => import("../views/students/preparing-for-school/college-prep/disabilities")),
		StudentsPreparingCollegeCyberSchoolStudents: lazy(() => import("../views/students/preparing-for-school/college-prep/cyber-school-students")),
		StudentsPreparingCollegeHomeSchooledStudents: lazy(() => import("../views/students/preparing-for-school/college-prep/home-schooled-students")),
		StudentsPreparingCollegeNontraditionalStudents: lazy(() => import("../views/students/preparing-for-school/college-prep/nontraditional-students")),
		StudentsPreparingChecklists: lazy(() => import("../views/students/preparing-for-school/checklists")),
		StudentsPreparingFindSchoolsLanding: lazy(() => import("../views/students/preparing-for-school/find-schools/index")),
		StudentsPreparingFindSchoolsWhatToLookFor: lazy(() => import("../views/students/preparing-for-school/find-schools/what-to-look-for")),
		StudentsPreparingFindSchoolsSchools: lazy(() => import("../views/students/preparing-for-school/find-schools/schools")),
		StudentsPreparingFindSchoolsVisitingCampus: lazy(() => import("../views/students/preparing-for-school/find-schools/visiting-campus")),
		StudentsPreparingFindSchoolsCampusLife: lazy(() => import("../views/students/preparing-for-school/find-schools/campus-life")),
		StudentsPreparingApplyLanding: lazy(() => import("../views/students/preparing-for-school/apply/index")),
		StudentsPreparingApplyAdmissions: lazy(() => import("../views/students/preparing-for-school/apply/admissions-decisions-letters")),
		StudentsPreparingApplyTenTips: lazy(() => import("../views/students/preparing-for-school/apply/college-application-tips")),
		StudentsPreparingApplyWaitList: lazy(() => import("../views/students/preparing-for-school/apply/waitlists")),
		StudentsPreparingStandardizedTestsLanding: lazy(() => import("../views/students/preparing-for-school/standardized-tests/index")),
		StudentsPreparingStandardizedTestsGED: lazy(() => import("../views/students/preparing-for-school/standardized-tests/ged")),
		StudentsPreparingStandardizedTestsGMAT: lazy(() => import("../views/students/preparing-for-school/standardized-tests/gmat")),
		StudentsPreparingStandardizedTestsGRE: lazy(() => import("../views/students/preparing-for-school/standardized-tests/gre")),
		StudentsPreparingStandardizedTestsLSAT: lazy(() => import("../views/students/preparing-for-school/standardized-tests/lsat")),
		StudentsPreparingStandardizedTestsMCAT: lazy(() => import("../views/students/preparing-for-school/standardized-tests/mcat")),
		StudentsPreparingStandardizedTestsPsatNmsqt: lazy(() => import("../views/students/preparing-for-school/standardized-tests/psat-nmsqt")),
		StudentsPreparingStandardizedTestsSAT: lazy(() => import("../views/students/preparing-for-school/standardized-tests/sat")),
		StudentsPreparingStandardizedTestsACT: lazy(() => import("../views/students/preparing-for-school/standardized-tests/act")),
		StudentsAssessmentsLanding: lazy(() => import("../views/students/self-assessments/index")),
		StudentsAssessmentsCharacter: lazy(() => import("../views/students/self-assessments/character")),
		StudentsAssessmentsImprovingStudyHabits: lazy(() => import("../views/students/self-assessments/improving-study-habits")),
		StudentsAssessmentsKindOfStudent: lazy(() => import("../views/students/self-assessments/kind-of-student")),
		StudentsAssessmentsLearningStyles: lazy(() => import("../views/students/self-assessments/learning-styles")),
		StudentsAssessmentsLearningStylesStyles: lazy(() => import("../views/students/self-assessments/learning-styles-styles"))
	},
	routeDataArray = [
		{
			path: "/",
			element: <viewComponents.HomePage />
		},
		{
			label: "About Us",
			path: "about",
			children: [
				{
					index: true,
					element: <viewComponents.AboutIndex />
				},
				{
					path: "contact-us",
					label: "Contact Us",
					children: [
						{
							index: true,
							element: <viewComponents.ContactUs />
						}
					]
				},
				{
					path: "web-banners",
					label: "Web Banners",
					children: [
						{
							index: true,
							element: <viewComponents.WebBanners />
						}
					]
				},
				{
					path: "site-map",
					label: "Site Map",
					children: [
						{
							index: true,
							element: <viewComponents.SiteMap />
						}
					]
				}
			]
		},
		{
			label: "Students",
			path: "students",
			children: [
				{
					index: true,
					element: <viewComponents.StudentsLanding />
				},
				{
					path: "career-planning",
					label: "Career Planning",
					children: [
						{
							index: true,
							element: <viewComponents.StudentsCareerLanding />
						},
						{
							path: "checklists",
							label: "Career Planning Checklists",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsCareerPlanningChecklists />
								}
							]
						},
						{
							path: "explore-salary-pay",
							label: "Explore Salary and Pay",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsCareerExploreLanding />
								},
								{
									path: "tuition-costs-earning-power",
									label: "Tuition Costs vs. Earning Power",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsCareerExplorePayOff />
										}
									]
								},
								{
									path: "learn-more-earn-more",
									label: "Learn More Earn More",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsCareerExploreMore />
										}
									]
								},
								{
									path: "what-to-expect-after-graduating",
									label: "What to Expect After Graduating",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsCareerExploreExpect />
										}
									]
								}
							]
						},
						{
							path: "find-careers",
							label: "Find Careers",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsCareerFindLanding />
								},
								{
									path: "career-clusters",
									label: "Career Clusters Activity",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsCareerFindClusters />
										},
										{
											exclude: true,
											label: "Career Clusters Activity",
											path: ":showAllResults",
											element: <viewComponents.StudentsCareerFindClusters />
										}
									]
								},
								{
									path: "careers",
									label: "Which Careers Match Your Skills?",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsCareerFindCareers />
										}
									]
								},
								{
									path: "hot-jobs",
									label: "Hot Jobs for the Future",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsCareerFindHotJobs />
										}
									]
								},
								{
									path: "not-about-money",
									label: "It's Not All About Money",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsCareerFindMoney />
										}
									]
								}
							]
						},
						{
							path: "get-experience",
							label: "Get Experience",
							children: [
								{
									index: true,
									element: <viewComponents.StudentCareerPlanningGetExperience />
								}
							]
						}
					]
				},
				{
					path: "paying-for-school",
					label: "Paying for School",
					children: [
						{
							index: true,
							element: <viewComponents.StudentsPayingLanding />
						},
						{
							path: "calculators",
							label: "Calculators",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsPayingCalculatorLanding />
								},
								{
									path: "estimate-monthly-payments",
									label: "Estimate Your Monthly Payments",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsCalculatorsEstimateMonthlyPayments />
										}
									]
								},
								{
									path: "reach-savings-goals",
									label: "Reach Your Savings Goals",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsCalculatorsReachSavingsGoals />
										}
									]
								},
								{
									path: "watch-savings-grow",
									label: "Watch Your Savings Grow",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsCalculatorsWatchSavingsGrow />
										}
									]
								}
							]
						},
						{
							path: "ways-to-pay",
							label: "Ways to Pay",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsPayingWaysToPayLanding />
								},
								{
									path: "reduce-college-costs",
									label: "Reduce College Costs",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPayingReduceCollegeCosts />
										}
									]
								},
								{
									path: "scholarship-scams",
									label: "Scholarship Scams",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPayingScholarshipScams />
										}
									]
								},
								{
									path: "grants",
									label: "Grants",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPayingGrants />
										}
									]
								},
								{
									path: "scholarships",
									label: "Scholarships",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPayingScholarships />
										}
									]
								},
								{
									path: "loans",
									label: "Loans",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPayingLoans />
										}
									]
								},
								{
									path: "work-study-employment",
									label: "Work Study Employment",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPayingWorkStudyEmployment />
										}
									]
								},
								{
									path: "military-service",
									label: "Military Service",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPayingMilitaryService />
										}
									]
								}
							]
						},
						{
							path: "managing-money",
							label: "Managing Money",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsPayingManagingMoney />
								}
							]
						},
						{
							path: "questions-and-answers",
							label: "Paying for School Q&A",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsPayingQuestionsAndAnswers />
								}
							]
						},
						{
							path: "saving-and-reducing-costs",
							label: "Saving and Reducing Costs",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsPayingSavingAndReducingCosts />
								}
							]
						},
						{
							path: "financial-aid-101",
							label: "Financial Aid 101",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsPayingFinancialAid101 />
								}
							]
						},
						{
							path: "financial-aid-checklists",
							label: "Financial Aid Checklists",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsPayingFinancialAidChecklists />
								}
							]
						},
						{
							path: "financial-aid-offers",
							label: "Financial Aid Offers",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsPayingFinancialAidOffers />
								}
							]
						},
						{
							path: "avoiding-sticker-shock",
							label: "Avoiding Sticker Shock",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsPayingAvoidingStickerShock />
								}
							]
						}
					]
				},
				{
					path: "preparing-for-school",
					label: "Preparing for School",
					children: [
						{
							index: true,
							element: <viewComponents.StudentsPreparingForSchoolLanding />
						},
						{
							path: "college-prep",
							label: "College Prep for Different Types of Students",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsPreparingCollegeLanding />
								},
								{
									path: "athletes",
									label: "Athletes",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingCollegeAthletes />
										}
									]
								},
								{
									path: "cyber-school-students",
									label: "Cyber School Students",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingCollegeCyberSchoolStudents />
										}
									]
								},
								{
									path: "disabilities",
									label: "Students With Disabilities",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingCollegeDisabilities />
										}
									]
								},
								{
									path: "home-schooled-students",
									label: "Home-Schooled Students",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingCollegeHomeSchooledStudents />
										}
									]
								},
								{
									path: "nontraditional-students",
									label: "Nontraditional Students",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingCollegeNontraditionalStudents />
										}
									]
								}
							]
						},
						{
							path: "find-schools",
							label: "Find Schools",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsPreparingFindSchoolsLanding />
								},
								{
									path: "schools",
									label: "Schools from A to Z",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingFindSchoolsSchools />
										}
									]
								},
								{
									path: "what-to-look-for",
									label: "What to Look for in a School: A Checklist",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingFindSchoolsWhatToLookFor />
										}
									]
								},
								{
									path: "visiting-campus",
									label: "Visiting Campus",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingFindSchoolsVisitingCampus />
										}
									]
								},
								{
									path: "campus-life",
									label: "Campus Life",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingFindSchoolsCampusLife />
										}
									]
								}
							]
						},
						{
							path: "standardized-tests",
							label: "Standardized Tests",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsPreparingStandardizedTestsLanding />
								},
								{
									path: "act",
									label: "ACT",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingStandardizedTestsACT />
										}
									]
								},
								{
									path: "ged",
									label: "GED Tests",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingStandardizedTestsGED />
										}
									]
								},
								{
									path: "gmat",
									label: "GMAT",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingStandardizedTestsGMAT />
										}
									]
								},
								{
									path: "gre",
									label: "GRE General Test",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingStandardizedTestsGRE />
										}
									]
								},
								{
									path: "lsat",
									label: "LSAT",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingStandardizedTestsLSAT />
										}
									]
								},
								{
									path: "mcat",
									label: "MCAT",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingStandardizedTestsMCAT />
										}
									]
								},
								{
									path: "psat-nmsqt",
									label: "PSAT/NMSQT",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingStandardizedTestsPsatNmsqt />
										}
									]
								},
								{
									path: "sat",
									label: "SAT",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingStandardizedTestsSAT />
										}
									]
								}
							]
						},
						{
							path: "apply",
							label: "Apply",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsPreparingApplyLanding />
								},
								{
									path: "admissions-decisions-letters",
									label: "Admissions Decisions Letters",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingApplyAdmissions />
										}
									]
								},
								{
									path: "college-application-tips",
									label: "College Application Tips",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingApplyTenTips />
										}
									]
								},
								{
									path: "waitlists",
									label: "Waitlists",
									children: [
										{
											index: true,
											element: <viewComponents.StudentsPreparingApplyWaitList />
										}
									]
								}
							]
						},
						{
							path: "checklists",
							label: "Preparing for School Checklists",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsPreparingChecklists />
								}
							]
						}
					]
				},
				{
					path: "self-assessments",
					label: "Self-Assessments",
					children: [
						{
							index: true,
							element: <viewComponents.StudentsAssessmentsLanding />
						},
						{
							path: "character",
							label: "How Strong is Your Character?",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsAssessmentsCharacter />
								}
							]
						},
						{
							path: "improving-study-habits",
							label: "Which Study Habits Can You Improve?",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsAssessmentsImprovingStudyHabits />
								}
							]
						},
						{
							path: "kind-of-student",
							label: "What Kind of Student Are You?",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsAssessmentsKindOfStudent />
								}
							]
						},
						{
							path: "learning-styles",
							label: "What's Your Learning Style?",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsAssessmentsLearningStyles />
								}
							]
						},
						{
							path: "learning-styles-styles",
							label: "The Learning Styles",
							children: [
								{
									index: true,
									element: <viewComponents.StudentsAssessmentsLearningStylesStyles />
								}
							]
						}
					]
				},
				{
					label: "MySmartBorrowing",
					path: "my-smart-borrowing",
					children: [
						{
							index: true,
							element: <viewComponents.StudentsMySmartBorrowing />
						}
					]
				}
			]
		},
		{
			label: "Parents",
			path: "parents",
			children: [
				{
					index: true,
					element: <viewComponents.ParentsLanding />
				},
				{
					path: "why-save",
					label: "Saving for Your Child's Future",
					children: [
						{
							index: true,
							element: <viewComponents.WhySave />
						}
					]
				},
				{
					path: "act-sat-scores",
					label: "Understanding ACT & SAT Scores",
					children: [
						{
							index: true,
							element: <viewComponents.ActSatScores />
						}
					]
				},
				{
					path: "skyrocketing-college-costs",
					label: "Skyrocketing College Costs",
					children: [
						{
							index: true,
							element: <viewComponents.SkyrocketingCollegeCosts />
						}
					]
				},
				{
					path: "scholarship-search-tips",
					label: "Scholarship Search Tips",
					children: [
						{
							index: true,
							element: <viewComponents.ScholarshipsSearchTips />
						}
					]
				},
				{
					path: "your-child-and-money",
					label: "Your Child & Money",
					children: [
						{
							index: true,
							element: <viewComponents.YourChildAndMoney />
						}
					]
				},
				{
					path: "you-and-the-fafsa",
					label: "You & the FAFSA",
					children: [
						{
							index: true,
							element: <viewComponents.YouAndTheFafsa />
						}
					]
				}
			]
		},
		{
			label: "Counselors",
			path: "counselors",
			children: [
				{
					index: true,
					element: <viewComponents.CounselorsLanding />
				},
				{
					path: "asca-career-standards",
					label: "ASCA National Standards",
					children: [
						{
							index: true,
							element: <viewComponents.AscaCareerStandards />
						}
					]
				},
				{
					path: "classroom-activity",
					label: "Classroom Activity",
					children: [
						{
							index: true,
							element: <viewComponents.ClassroomActivity />
						}
					]
				},
				{
					path: "forms",
					label: "Forms",
					children: [
						{
							index: true,
							element: <viewComponents.CounselorsForms />
						}
					]
				},
				{
					path: "set-up-job-shadowing-program",
					label: "Setting up a Job Shadowing Program",
					children: [
						{
							index: true,
							element: <viewComponents.JobShadowing />
						}
					]
				},
				{
					path: "financial-aid-event",
					label: "Hosting a Financial Aid Event",
					children: [
						{
							index: true,
							element: <viewComponents.FinancialAidEvent />
						}
					]
				}
			]
		},
		{
			path: "*",
			element: <viewComponents.PageNotFound />
		}
	];

export { routeDataArray, viewComponents };